<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">
            {{
              $t("message.edit_m", {
                m: $t("message.filial"),
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0" v-loading="loadingData">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-form ref="form" :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item :label="$t('message.name')" prop="name" class="label_mini">
                  <el-input
                    :placeholder="$t('message.name')"
                    v-model="form.name"
                    size="medium"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  :label="$t('message.categories')"
                  class="label_mini"
                  prop="category_ids"
                >
                  <select-categories
                    ref="Categories"
                    v-model="form.category_ids"
                    :category_ids="form.category_ids"
                    :data="JSON.parse(JSON.stringify(this.categories)).filter(item => item.has_child === false)"
                    :size="'medium'"
                    :placeholder="$t('message.categories')"
                  >
                  </select-categories>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import selectCategories from "@/components/multiSelects/multi-select-categories";

export default {
  mixins: [form, drawer, show],
  components: {
    selectCategories,
  },
  name: "filials",
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "filials/rules",
      model: "filials/model",
      columns: "filials/columns",
      categories: "categories/inventory",
    }),
  },
  methods: {
    ...mapActions({
      update: "filials/update",
      show: "filials/show",
    }),
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.update(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (res.status == 200) this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
  },
};
</script>
